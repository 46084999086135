<template>
  <div :class="$style.promos">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          :valueNames="['code', 'clientName']"
          placeholder="Поиск"
          @querySearch="querySearch"
          @selectItem="handleSelectPromo"
          @handleFindItems="getList()"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-button
        :class="$style.button"
        type="primary"
        @click="$router.push('gift-promos/create')"
      >
        Создать
      </el-button>
    </div>
    <el-table :data="giftPromos" stripe>
      <el-table-column prop="code" label="Код" width="170"> </el-table-column>
      <el-table-column prop="clientName" label="Имя клиента"> </el-table-column>
      <el-table-column prop="clientPhone" label="Телефон клиента">
      </el-table-column>
      <el-table-column prop="createdAt" label="Дата создания">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Тип">
        <template slot-scope="scope">
          {{ getPromoTypeName(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="value" label="Сумма"> </el-table-column>
      <el-table-column
        prop="minPriceToApply"
        label="Мин. Сумма для использования"
      ></el-table-column>
      <el-table-column prop="isUsed" label="Использован" width="130">
        <template slot-scope="scope">
          <input
            type="checkbox"
            onclick="return false"
            :checked="scope.row.isUsed"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="isDiscountable"
        label="Применим к акционным товарам"
        width="110"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            onclick="return false"
            :checked="scope.row.isDiscountable"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            :view-internal-link="'gift-promos/' + String(scope.row.id)"
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'

import { formatDate } from '@/helpers/index.js'
import {
  PROMOCODE_TYPE_DISPLAY,
  PROMOCODE_TYPE,
} from '@/constants/promocodeTypes'
import { ADDWINE_ADMIN_ROUTES, getRoute } from '@/constants/routing'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'

export default {
  components: { ActionButtons, Autocomplete },
  data() {
    return {
      giftPromos: [],
      total: 0,
      page: 1,
      limit: 20,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getPromoTypeName(type) {
      return type == PROMOCODE_TYPE.NOMINAL
        ? PROMOCODE_TYPE_DISPLAY.NOMINAL
        : PROMOCODE_TYPE_DISPLAY.PERCENTAGE
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      if (this.search) {
        query.search = this.search
      }

      const { value, error } =
        await delivery.PaymentServiceCore.GiftPromosActions.getList(query)

      loading.close()

      if (error) return

      this.giftPromos = value.data
      this.total = value.meta.count
    },
    async remove(id) {
      const isConfirm = confirm('Вы точно хотите удалить промокод?')
      if (!isConfirm) {
        return
      }
      const res = await delivery.PaymentServiceCore.GiftPromosActions.delete(id)
      if (!res.error) {
        this.getList()
      }
    },
    formatDate(date) {
      return !date ? '' : formatDate(date)
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.PaymentServiceCore.GiftPromosActions.getList({
          limit: 100,
          page: this.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
    handleSelectPromo(selectedPromo) {
      this.$router.push(
        this.getRoute({
          route: ADDWINE_ADMIN_ROUTES.PROMOTIONS.PROMOS.GIFT_PROMOS.VIEW,
          params: { id: selectedPromo.id },
        }),
      )
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.promos {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: space-between;
    .button {
      margin-bottom: 1rem;
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
